<template>
  <tr>
    <td class="fixed-column"
     v-if="showSelect"
    >
    </td>
    <template v-for="th in headers">
      <td
        :key="th.value"
        :class="th.cellClass || ''"

      >
        <template v-if="th.filters && th.filters.length > 0">
          <FilterDialog :filters="th.filters" :label="th.text" />
        </template>
        <template v-else>
          <v-text-field
            v-if="th.filter && th.filter.type === 'text'"
            v-model="th.filter.value"
            dense
            outlined
            :placeholder="th.filter.place_holder || ''"
            hide-details
            full-width
            clearable
            style="min-width:30px;"
            class="filter-input"
          />
          <v-text-field
            v-if="th.filter && th.filter.type === 'number'"
            v-model="th.filter.value"
            type="number"
            dense
            outlined
            :placeholder="th.filter.place_holder || ''"
            hide-details
            full-width
            clearable
            style="min-width:30px;"
            class="filter-input"
          />
          <v-autocomplete
            v-if="th.filter && th.filter.type === 'select'"
            v-model="th.filter.value"
            :items="th.filter.values || []"
            :item-text="th.filter.label"
            :item-value="th.filter.id"
            :placeholder="th.filter.place_holder || ''"
            :no-data-text="$t('Pas de données')"
            :no-results-text="$t('Aucun résultat')"
            full-width
            outlined
            dense
            hide-details
            :multiple="th.filter.multiple"
            clearable
            chips
            deletable-chips
            style="min-width: 60px; max-width: 300px"
          ></v-autocomplete>
          <template v-if="th.filter && th.filter.type === 'date'">
          <date-picker
            v-if="!th.filter.range"
            v-model="th.filter.value"
            :label="th.filter.place_holder || ''"
            first-day-of-week="1"
            no-title
            scrollable
            :range="false"
            :type="th.filter.calendarType"
            style="min-width: 67px"
          />
          <date-range-picker
            v-else
            v-model="th.filter.value"
            :label="th.filter.place_holder || ''"
            :max-date="th.filter.noLimit?null:today"
            first-day-of-week="1"
            no-title
            scrollable
            :type="th.filter.month?'month':'date'"
            style="min-width: 67px"
          />
        </template>

        </template>
      </td>
    </template>
  </tr>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import { avatarText, formatDate } from '@core/utils/filter'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import FilterDialog from '@/components/FilterDialog'
export default {
  components: { FilterDialog, DateRangePicker, DatePicker },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const items = computed({
      get: () => props.headers.map(ele => {
        ele.menu = ele.menu || false

        return ele
      }),
      set: value => emit('update:object', value),
    }, { deep: true })

    return {
      formatDate,
      items,
      today,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
      },
    }
  },
}
</script>
