<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date1"
    persistent
    width="290px"
    :label="label"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-combobox
        v-if="range"
        v-model="computedDateFormatted"
        dense
        outlined
        multiple
        chips
        small-chips
        clearable
        hide-details
        style="min-width: 67px; max-width: 300px"
        :disabled="disabled"
        :placeholder="label || ''"
        :prepend-inner-icon="icons.mdiCalendarRange"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="date1 = null"
      ></v-combobox>
      <v-text-field
        v-else
        v-model="computedDateFormatted"
        :label="label"
        :disabled="disabled"
        :append-icon="icons.mdiCalendar"
        outlined
        dense
        readonly
        clearable
        v-bind="attrs"
        hide-details
        v-on="on"
        @click:clear="date1 = null"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date1"
      :min="minDate"
      :max="maxDate"
      locale="fr"
      scrollable
      :type="type"
      first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date1)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { format, parse } from 'date-fns'

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export default {
  model: {
    prop: 'dateItem',
    event: 'input',
  },
  props: {
    dateItem: {
      type: String,
      default: () => (new Date(Date.now())).toISOString().substr(0, 10),
    },
    minDate: {
      type: String,
      default: () => null,
    },
    maxDate: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'date',
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const dateFormatted = ref('')
    const item = computed({
      get: () => props.dateItem,
      set: value => emit('update:date', value),
    })
    const isRange = computed({
      get: () => props.range,
    })
    const date1 = ref(item.value)

    const formatDate = d => {
      if (!d) return null
      if (!isRange.value) {
        const [year, month, day] = d.split('-')

        return `${day}/${month}/${year}`
      }

      return d
    }
    const parseDate = d => {
      if (!d) return null

      if (!isRange) {
        const [day, month, year] = d.split('/')

        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }

      return d
    }

    const computedDateFormatted = computed({
      get: () => formatDate(date1.value),
    })

    watch(item, val => {
      dateFormatted.value = formatDate(val)

      // emit('input', val)
    })
    watch(date1, val => {
      dateFormatted.value = formatDate(val)
      emit('input', val)
    })

    return {
      item,
      modal,
      date1,
      dateFormatted,
      parseDate,
      computedDateFormatted,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
