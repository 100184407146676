<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date1"
    persistent
    width="290px"
    :label="label"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-combobox
        v-model="computedDateFormattedRange"
        dense
        outlined
        multiple
        chips
        small-chips
        clearable
        hide-details
        style="min-width: 67px; "
        :placeholder="label || ''"
        :label="label || ''"
        :prepend-inner-icon="icons.mdiCalendarRange"
        readonly
        v-bind="attrs"
        v-on="on"
        @click="modal = true"
      ></v-combobox>
    </template>
    <v-date-picker
      v-model="date1"
      :min="minDate"
      :max="maxDate"
      locale="fr"
      :range="true"
      :type="type"
      scrollable
      first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date1)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { format, parse } from 'date-fns'

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export default {
  model: {
    prop: 'dateItem',
    event: 'input',
  },
  props: {
    dateItem: {
      type: Array,
      default: () => [],
    },
    minDate: {
      type: String,
      default: () => null,
    },
    maxDate: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'date',
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const dateFormatted = ref('')
    const item = computed({
      get: () => props.dateItem,
      set: value => emit('update:date', value),
    })

    const date1 = ref(/* item.value */)

    const formatDateRange = d => {
      if (!d) return null

      if (d.length === 0) return []

      const d1 = d[0] || null
      const d2 = d[1] || null
      const dates = []
      if (d1) {
        const [day, month, year] = d[0].split('-')
        if (props.type === 'month') {
          dates.push(`${day}/${month.padStart(2, '0')}`)
        } else {
          dates.push(`${year}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`)
        }
      }
      if (d2) {
        const [day1, month1, year1] = d2.split('-')
        if (props.type === 'month') {
          dates.push(`${day1}/${month1.padStart(2, '0')}`)
        } else {
          dates.push(`${year1}/${month1.padStart(2, '0')}/${day1.padStart(2, '0')}`)
        }
       /* dates.push(`${year1}/${month1.padStart(2, '0')}/${day1.padStart(2, '0')}`)*/
      }

      return dates
    }
    const parseDateRange = d => {
      if (!d) return null

      if (d.length === 0) return []
      const d1 = d[0] || null
      const d2 = d[1] || null
      const dates = []
      if (d1) {
        const [day, month, year] = d1.split('/')
        dates.push(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)
      }
      if (d2) {
        const [day1, month1, year1] = d2.split('/')
        dates.push(`${year1}-${month1.padStart(2, '0')}-${day1.padStart(2, '0')}`)
      }

      return dates
    }

    const computedDateFormattedRange = computed({
      get: () => formatDateRange(date1.value),
      set: value => {
        emit('input', parseDateRange(value))
        date1.value = parseDateRange(value)
      },
    })

    watch(item, val => {
      dateFormatted.value = formatDateRange(val)

      // emit('input', val)
    })
    watch(date1, val => {
      dateFormatted.value = formatDateRange(val)
      emit('input', val)
    }, { deep: true })

    return {
      item,
      modal,
      date1,
      dateFormatted,
      parseDateRange,
      computedDateFormattedRange,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
